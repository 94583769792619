.ant-picker-panel-container {
  left: 0 !important;
  margin-left: 0 !important;
  transform: translateX(0) !important;
  width: 100% !important;
  max-width: 100% !important;
}

.ant-picker-clear {
  display: none !important;
}
