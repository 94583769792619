/* This is to hide the counter helper for number fields. They're not required and look bad. */
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}

.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* This is to hide the counter helper for number fields. They're not required and look bad. */
.numberInputField[type="number"] {
  -moz-appearance: textfield;
}

.numberInputField::-webkit-outer-spin-button,
.numberInputField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
