.logoText {
  font-family: "Red Hat Display", sans-serif;
  font-weight: bolder;
}

.pulse {
  margin: 100px;
  display: block;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: rgb(255, 106, 20);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 106, 20, 0.3);
  animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
  }

  50% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 106, 20, 0.3);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
  }
}

.table-row-light {
  background: #e5ebf2 !important;
}

.table-row-light:hover td {
  background: #e5ebf2 !important;
}

.pointer {
  cursor: pointer;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.ant-modal {
  width: initial !important;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
  }

  50% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 106, 20, 0.3);
    box-shadow: 0 0 0 20px rgba(255, 106, 20, 0.3);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 106, 20, 0.3);
  }
}
