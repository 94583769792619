
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

* {
    font-family: "Red Hat Display", Ariel, -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-weight: 500;
    font-feature-settings: normal !important;
    font-variant-numeric: normal !important;
}
