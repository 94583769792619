.dropdown-btn > .ant-dropdown-trigger {
  border: none;
}

.dropdown-btn > .ant-btn-compact-first-item {
  border: none;
  box-shadow: none;
}

.dropdown-btn > .ant-dropdown-trigger > span {
  background-color: white !important;
}
